// extracted by mini-css-extract-plugin
export var rootTitle = "DesktopProductsMenu-module--rootTitle--1ttNM";
export var active = "DesktopProductsMenu-module--active--1Vtrf";
export var title = "DesktopProductsMenu-module--title--3o3OW";
export var expandedMenu = "DesktopProductsMenu-module--expandedMenu--1YQpd";
export var collapsedMenu = "DesktopProductsMenu-module--collapsedMenu--Dtnxi";
export var svgIcon = "DesktopProductsMenu-module--svgIcon--_NVpK";
export var rootContainer = "DesktopProductsMenu-module--rootContainer--3W0vx";
export var rootContainerVisible = "DesktopProductsMenu-module--rootContainerVisible--2EKQ2";
export var fadeIn = "DesktopProductsMenu-module--fadeIn--1QIOS";
export var rootMenu = "DesktopProductsMenu-module--rootMenu--3ciQW";